#team {
  text-align: center;
  padding: 50px 0;
}

#team .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#team .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#team .team {
  margin: 10px;
  flex: 1 1 calc(50% - 20px); /* Adjusts width to fit two cards per row with margin */
  max-width: calc(
    50% - 20px
  ); /* Ensures the cards don't exceed half of the container width */
}

#team .card {
  margin: 0 auto;
  max-width: 300px;
}

.custom-card-container {
  max-width: 500px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.custom-card-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  max-width: 500px;
  min-height: 500px;
  margin: 0 auto;
}

.custom-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.custom-card .card-image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 400px;
  background-color: var(--gray-5);
}

.custom-card p {
  flex-grow: 1;
}

.team-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 767px) {
  .custom-card-container {
    min-height: auto;
  }

  .custom-card .card-image {
    height: auto;
  }
}
